import NextImage from "next/legacy/image"
import Container from './Container'
import resolveColors from '@utils/styles/resolveColors'
import mix from '@utils/styles/mix'

import styles from './Section.module.css'

const COLORS = {
    gray: 'gray',
    green: 'green',
    greenAlt: 'greenAlt',
    white: 'white',
}

const BACKGROUND = {
    DEFAULT_FORMAT: 'png',
    DEFAULT_FOLDER: '/new',
}

function Section ({ children, className, useContainer = true, ...rest }) {
    const [colors, otherProps] = resolveColors(rest, COLORS, styles)
    return <section className={mix([styles.section, className, colors])} {...otherProps}>
        {
            useContainer
                ? <Container>{children}</Container>
                : children
        }
    </section>
}

function Background ({ alt, assetFolder = BACKGROUND.DEFAULT_FOLDER, className, format = BACKGROUND.DEFAULT_FORMAT, left, name, right = true, size, src, ...rest }) {
    if (Array.isArray(size)) return <span className={mix([styles.background, left && styles.left, right && styles.right, className])}><NextImage
        alt={alt}
        height={size[1]}
        src={src || `${assetFolder}/${name}.${format}`}
        width={size[0]}
    /></span>

    return <img
        alt={alt}
        className={mix([styles.background, left && styles.left, right && styles.right, className])}
        src={src || `${assetFolder}/${name}.${format}`}
        {...rest}
    />
}

function BackgroundSection ({ children, imgProps, size, useContainer = true, ...rest}) {
    return <section className={mix([styles.section, styles['bg-section']])} {...rest}>
        <Background {...imgProps} className={styles['bg-section-img']} size={size} />
        {
            useContainer
                ? <Container>{children}</Container>
                : children
        }
    </section>
}

function Circle ({ className, radius, ...rest }) {
    return <div className={mix([styles.circle, className])} {...rest} />
}

function Box ({ children, ...rest }) {
    const [colors, otherProps] = resolveColors(rest, COLORS, styles)
    return <div className={mix([styles['box'], colors])} {...otherProps}>
        {children}
    </div>
}

function Divider () {
    return <hr className={styles.hr} />
}

Box.Div = Divider

Section.COLORS = COLORS
Section.Background = Background
Section.BackgroundSection = BackgroundSection
Section.Circle = Circle
Section.Box = Box


export default Section