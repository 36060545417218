import { FUEL_CAPTIONS } from '@constants/fuels'
import { PROPER_NAME_DELIMITER } from '../../../../next-spl-part-utils/constants/delimiters'
import mix from '@utils/styles/mix'
import styles from './OfferShortSpec.module.css'

function OfferShortSpec({
	doYear = true,
	inject,
	offer,
	specTextArray,
	oneliner,

	teal,

	...rest
}) {
	specTextArray = specTextArray || getSpec(offer, doYear)
	if (!Array.isArray(specTextArray) || specTextArray.length < 1)
		return <span />

	const _className = mix([
		styles.spec,
		oneliner && styles['one-line'],
		teal && styles.teal,
	])
	return (
		<div className={_className} {...rest}>
			{specTextArray.map((string, idx) => (
				<span key={idx}>{string}</span>
			))}
			{inject}
		</div>
	)
}

function getBuySpec(offer) {
	const spec = []

	if (!offer) return spec

	appendRangeSpec(offer, 'procuction_year', 'r.', spec)
	appendRangeSpec(offer, 'mileage', 'km', spec)
	appendRangeSpec(offer, 'motohours', 'h', spec)
	appendRangeSpec(offer.data, 'displacement_capacity', 'cm³', spec)
	appendRangeSpec(offer.data, 'engine_power', 'KM', spec)

	appendSpec(offer.part_kind, '', spec)
	appendSpec(offer.category, '', spec)
	appendSpec(offer.subcategory, '', spec)
	appendSpec(offer.kind, '', spec)

	return spec
}

function getAltSpec(offer, trimTitle) {
	const spec = []
	appendSpec(offer.data?.displacement_capacity, 'cm³', spec)
	appendSpec(offer.data?.engine_power, 'KM', spec)
	appendSpec(removeTrimTitle(offer?.tracking?.trim?.offerTitle || offer.data?.equipment, trimTitle), '', spec)
	return spec
}

function getSpec(offer, doYear = false) {
	const spec = []

	if (doYear) appendSpec(offer.production_year, '', spec)
	appendSpec(offer.mileage, 'km', spec)
	appendSpec(FUEL_CAPTIONS[offer.fuel] || offer.fuel, '', spec)
	appendSpec(offer.data?.displacement_capacity, 'cm³', spec)
	appendSpec(offer.motohours, 'h', spec)
	appendSpec(offer.category, '', spec)
	appendSpec(offer.subcategory, '', spec)
	appendSpec(offer.kind, '', spec)
	appendSpec(
		offer.part_category &&
			offer.part_category.split(PROPER_NAME_DELIMITER),
		'',
		spec,
	)
	// appendSpec(offer.part_producer, '', spec)
	appendSpec(offer.part_vehicle, '', spec)
	appendSpec(offer.service_kind, '', spec)

	return spec
}

function appendSpec(prop, unit, array) {
	if (prop != null) {
		if (Array.isArray(prop))
			for (const p of prop) {
				appendSpec(p, unit, array)
			}
		else {
			if (array.length > 0) array.push('·')
			array.push(`${prop} ${unit}`.trim())
		}
	}
}

function appendRangeSpec(
	store,
	prop,
	unit,
	array,
	fromPrefix = 'od',
	toPrefix = 'do',
	separator = '_',
) {
	if (!store || !prop || !array) return
	const from = [fromPrefix, prop].join(separator)
	const to = [toPrefix, prop].join(separator)
	const fromValue = store[from]
	const toValue = store[to]
	const value = [fromValue, toValue].filter(Boolean).join(' - ')
	if (value) {
		if (array.length > 0) array.push('·')
		array.push(`${value} ${unit}`.trim())
	}
}

function removeTrimTitle (text, trimTitle) {
	if (!text) return
	const lookFor = (trimTitle || '') + ' / '
	return text.startsWith(lookFor)
		? text.slice(lookFor.length)
		: text
}

export default OfferShortSpec
export {
	getAltSpec,
	getSpec,
	getBuySpec,
}