import mix from '@utils/styles/mix'
import styles from './OfferFeatureGrid.module.css'

const HOST = process.env.NEXT_PUBLIC_HOST

class FieldIcon {
    static pluckFrom (offer, field) {
        return field.split('.').reduce((store, field) => {
            if (typeof store != 'object') return store
            store = store[field] || ''
            return store
        }, offer)
    }

    constructor (field, icon, unit) {
        this.field = field
        this.icon = icon
        this.unit = unit || ''
    }
}

function OfferFeatureGrid ({ offer, fieldIcons, forList, iconFolder = '/icons/offer', iconExt = '.svg' }) {
    const featuresJSX = []
    for (const fieldIcon of fieldIcons) {
        const { field, icon, unit } = fieldIcon
        const value = FieldIcon.pluckFrom(offer, field)
        if (!value) continue
        const jsx = <span key={field}>
            { icon && <img alt={icon} src={`${HOST}${iconFolder}/${icon}${iconExt}`} /> }
            { value } { unit }
        </span>
        featuresJSX.push(jsx)
    }
    const className = mix([styles.grid, forList && styles['for-list']])
    return <div className={className}>{featuresJSX}</div>
}

export {
    FieldIcon,
    OfferFeatureGrid,
}